const ChargeEvents = {
    SAVED: 'SAVED',
    EDITED: 'EDITED',
    DELETED: 'DELETED',
};
module.exports.ChargeEvents = ChargeEvents;

const EventBus = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    }
};

module.exports.EventBus = EventBus;