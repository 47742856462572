export const validatePassword = (passwordOne, passwordTwo) => {

    let result = {
        containsUL: false, 
        containsLL: false, 
        containsN: false, 
        containsSC: false, 
        contains8C: false, 
        passwordMatch: false,
        allValid: false
    };

    // has uppercase letter
    result.containsUL = (passwordOne.toLowerCase() != passwordOne);
    // has lowercase letter
    result.containsLL = (passwordOne.toUpperCase() != passwordOne);
    // has number
    result.containsN = (/\d/.test(passwordOne));
    // has special character
    result.containsSC = (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(passwordOne));
    // has 8 characters
    result.contains8C = (passwordOne.length >= 8);
    // passwords match
    result.passwordMatch = (passwordOne !== "" && passwordOne === passwordTwo);

    // all validations passed
    result.allValid = 
        (result.containsUL 
        && result.containsLL 
        && result.containsN 
        && result.containsSC 
        && result.contains8C 
        && result.passwordMatch);

    return result;
}
