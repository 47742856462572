import { STORED_NOTIFICATIONS, DISMISSED_NOTIFICATIONS } from '../constants';
import { ChargeClient } from './charge-client';
import { booleanBinarySearch, booleanBinarySearchObjectId } from '../utils/searchUtils.js';
import dayjs from 'dayjs';
import { getDateKey } from '../utils/dateUtils.js';

export default class NotificationProvider {

    #chargeClient  = new ChargeClient();

    // fetches appointments that are complete but don't have charge tickets yet
    async generateApptNotifications(user, startTime, endTime) {
        console.log(`Notifications :: generating...`);
        // const userReference = getUser().kareoId; // testUserKareoId; // getUser().kareoId;

        try {
            let totalNotifications = [];
            // const storedNotifications = []; //localStorage.getItem(STORED_NOTIFICATIONS) ? JSON.parse(localStorage.getItem(STORED_NOTIFICATIONS)) : [];
            let dismissedNotifications = localStorage.getItem(DISMISSED_NOTIFICATIONS) ? JSON.parse(localStorage.getItem(DISMISSED_NOTIFICATIONS)) : [];

            // totalNotifications.push(...storedNotifications);

            // totalNotifications.forEach(n => {
            //     if (n.category === 'Remaining Patient'){
            //         n.title = 'Patient charge ticket missing';
            //         const theDay = new Date(n.data.date);
            //         const currentDate = dayjs(theDay);
            //         const formattedDate = currentDate.format('MM/DD/YYYY');
            //         const dayOfWeek = theDay.toLocaleString('en-us', {  weekday: 'long' })
            //         n.displayDate = `${dayOfWeek}, ${formattedDate}`;
            //         n.removeMe = false;
            //     }
            // });

            let appointments = await this.#chargeClient.getAppointments({
                startDate: startTime,
                endDate: endTime,
                userIds: [user.id]
            });

            // Add time stamp with adjusted time zone
            appointments = appointments.map(x => {
                const fixedDate =  x.date.toISOString().replace('Z', '-08:00');
                return {
                    ...x,
                    dateKey: getDateKey(new Date(`${x.date}`)),
                    fixedDate: new Date(Date.parse(fixedDate))
                };
            });

            const chargeTickets = await this.#chargeClient.getTickets({
                startDate: startTime,
                endDate: endTime,
                userIds: [user.id]
            });

            // remove existing notifications if appt status has changed
            appointments.forEach(ap => {
                let foundNotification = totalNotifications.find(x => x.data.id === ap.id);
                if (foundNotification && foundNotification.data.appointmentStatus !== ap.appointmentStatus) {
                    console.log(`Notifications :: Removing notification: `, foundNotification);
                    foundNotification.removeMe = true;
                }
            });

            totalNotifications = totalNotifications.filter(x => !x.removeMe);

            console.log("Notifications :: ALL appointments: ", appointments);
            const filteredAppointments = appointments.filter(appt => {
                // const condition1 = appt.resourceName === user.kareoId; // this one fails when Kareo has a resourceName that doesn't match in our DB (i.e. 'DAREN BADURA' vs. 'DAREN L. BADURA')
                // const condition1 = appt.userId === user.id; // testUserId;
                // const condition1 = appt.appointmentStatus !== "Checkedout";
                const condition2 = appt.appointmentStatus !== "Cancelled";
                const condition3 = appt.appointmentStatus !== "Rescheduled";
                const condition4 = appt.appointmentStatus !== "No-show";
                const condition5 = appt.appointmentStatus !== "NoShow";
                // const condition6 = appt.appointmentStatus !== "Check-out";
                // const condition7 = appt.appointmentStatus !== "Checkout";

                // return condition1 && condition2 && condition3 && condition4 && condition5; // && condition6 && condition7;
                return condition2 && condition3 && condition4 && condition5; // && condition6 && condition7;
            });
            console.log("Notifications :: User's appointments: ", filteredAppointments);


            // const chargeTickets = await getManyTickets(startTime, endTime);
            // const filteredTickets = chargeTickets.filter(ticket => ticket["user"]["kareoId"] === user.kareoId);// this one fails when Kareo has a resourceName that doesn't match in our DB (i.e. 'DAREN BADURA' vs. 'DAREN L. BADURA')
            // const filteredTickets = chargeTickets.filter(ticket => ticket["user"]["id"] === `${user.id}`); // user.kareoId);
            console.log("Notifications :: User's tickets: ", chargeTickets);

            const appointmentsWithNoMatch = filteredAppointments.filter(app => {
                return !chargeTickets.some(ticket => {
                    const ticketDate = new Date(ticket.date);
                    const apptDate = new Date(app.date);
                    // const apptDate = new Date(app.fixedDate);
                    const doMatch = (
                        apptDate.getFullYear() === ticketDate.getFullYear() &&
                        apptDate.getMonth() === ticketDate.getMonth() &&
                        apptDate.getDate() === ticketDate.getDate() &&
                        app.patientName.toLowerCase().trim() === ticket.patientName.toLowerCase().trim()
                        // app.mrn === ticket.mrn
                    );
                    return doMatch;
                });
            });

            // console.log("Notifications :: appointmentsWithNoMatch = ", appointmentsWithNoMatch);

            appointmentsWithNoMatch.forEach(appt => {

                console.log(`Notifications :: Found appt without ticket...`);
                // let foundStoredNotification = storedNotifications.find(x => `${x.id}` === `${appt.kareoId}`);
                // if (foundStoredNotification) { return; }

                let foundDismissedNotification = dismissedNotifications.find(x => `${x.id}` === `${appt.kareoId}`);
                if (foundDismissedNotification) {
                    if (+(new Date()) < +(new Date(`${foundDismissedNotification.expires}`)) ) {
                        return; // only include if the dismissed notification has expired
                    } else {
                        dismissedNotifications = dismissedNotifications.filter(x => x.id !== foundDismissedNotification.id);
                        dismissedNotifications.sort((a, b) => a.id - b.id);
                        localStorage.setItem(DISMISSED_NOTIFICATIONS, JSON.stringify(dismissedNotifications));
                    }
                }

                // if (booleanBinarySearch(dismissedNotifications, parseInt(appt.kareoId))) {
                //     return;
                // } else if (booleanBinarySearchObjectId(storedNotifications, parseInt(appt.kareoId))) {
                //     return;
                // }

                const newNotification = {};
                const appDate = new Date(appt.date);
                // const appDate = new Date(appt.fixedDate);

                const currentDate = dayjs(appDate);
                const formattedDate = currentDate.format('MM/DD/YYYY');
                const dayOfWeek = appDate.toLocaleString('en-us', {  weekday: 'long' })

                newNotification.id = parseInt(appt.kareoId);
                newNotification.date = new Date(appt.date);
                // newNotification.date = new Date(appt.fixedDate);
                newNotification.category = "Remaining Patient";
                newNotification.title = "Patient charge ticket missing";
                newNotification.patient = `${appt.patientName.toUpperCase()}`;
                newNotification.displayDate = `${dayOfWeek}, ${formattedDate}`;
                newNotification.data = appt;

                totalNotifications.push(newNotification);
            });

            totalNotifications.sort((a, b) => b.date - a.date);
            console.log("Notifications :: Total Notifications: ", totalNotifications);
            localStorage.setItem(STORED_NOTIFICATIONS, JSON.stringify(totalNotifications));

            return totalNotifications;

        } catch (e) {
            console.log("Notifications :: Error retrieving Kareo Appointment and Charge Ticket Data: ", e);
        }
    }

    // fetches change requests for admins to approve/deny
    async generateTicketRequestNotifications() {
        try {
            const changeRequests = await this.#chargeClient.findTicketChangeRequests({
                onlyOpen: true
            });
            console.log(`[NotificationProvider] :: API => Found change requests: `, changeRequests);
            return changeRequests;
        } catch (ex) {
            console.error(`Error loading change requests: `, ex);
            return [];
        }

    }
}
