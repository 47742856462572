import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { GOOGLE_FORMS_SURVEY, SURVEY_KEY } from "../constants";

const Survey = ({ disableSurveyBanner }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Paper square elevation={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px', padding: '4px', width: '100%', background: '#45b6f3', color: '#fff' }}>
      <Typography variant="h6" component="div" style={{ fontSize: '1.2rem' }}>
        HPM Charge Survey
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Button size="small" variant="text" color="primary" onClick={disableSurveyBanner} style={{ fontSize: '0.7rem', textTransform: 'none' }}>
          Don't Show Again
        </Button>
        <Button size="small" variant="contained" onClick={handleOpen}>
          Take Survey
        </Button>

      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth style={{ padding: 0 }}>
        <DialogContent style={{ padding: 0, height: '90vh' }}>
          <iframe src={GOOGLE_FORMS_SURVEY} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0"
            style={{ margin: 0 }}
          >Loading…</iframe>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default Survey;