import { unitRateExpections } from '../env/connection';
// import { GET_KAREO_LOCATIONS, GET_LOCATION_CPTS } from '../components/queries/queries';
import { api } from '../env/connection';
import { tokenProvider } from '../providers/tokenProvider';

export const LocationsUnitsProvider = (() => {

    let kareoLocations = [];
    let cptTiers = [];
    let locationCpts = [];

    async function refreshData() {
        await new Promise(resolve => {
            const checkToken = setInterval(() => {
                if (tokenProvider.token !== null) {
                    clearInterval(checkToken)
                    resolve()
                }
            }, 250)
        })

        if (!kareoLocations.length) {
            // let locationsResponse = await kareoLocationsQuery.refetch();
            // kareoLocations = locationsResponse.data.kareoLocations;

            const kareoLocationsOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "kareoLocations",
                    variables: { "filter": "" },
                    query: `query kareoLocations($filter: String!) {
                        kareoLocations(filter: $filter) {
                            id
                            kareoId
                            practiceName
                            chargeType
                            chargeTypeDate
                            tierId
                            chargeCap
                            tiers {
                                id
                                locationId
                                tierId
                                tierName
                                startDate
                                endDate
                            }
                            facilityfees {
                                id
                                feeName
                                amount
                                billingType
                                createdById
                                modifiedById
                                createdDate
                                modifiedDate
                                effectiveDate
                            }
                        }
                    }`
                })
            };

            let locationsResponse = await fetch(api, kareoLocationsOptions);
            let locationsJson = await locationsResponse.json();
            kareoLocations = locationsJson.data.kareoLocations;
            // console.log(`kareoLocations = `, kareoLocations);
        } else {
            console.log(`ALREADY LOADED :: kareoLocations = `, kareoLocations);
        }

        if (!cptTiers.length) {
            // let locationCptsResponse = await locationCptsQuery.refetch();
            // locationCpts = locationCptsResponse.data.locationCpts;

            const cptTiersOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "cptTiers",
                    variables: { "filter": "" },
                    query: `query cptTiers($filter: String!) {
                        cptTiers(filter: $filter) {
                            id,
                            tierId,
                            tierName,
                            cptId,
                            cptCode,
                            primaryUnits,
                            secondaryUnits
                        }
                    }`
                })
            };

            let cptTiersResponse = await fetch(api, cptTiersOptions);
            let cptTiersJson = await cptTiersResponse.json();
            cptTiers = cptTiersJson.data.cptTiers;
            // console.log(`locationCpts = `, locationCpts);
        } else {
            console.log(`ALREADY LOADED :: cptTiers = `, cptTiers);
        }

        if (!locationCpts.length) {
            // let locationCptsResponse = await locationCptsQuery.refetch();
            // locationCpts = locationCptsResponse.data.locationCpts;

            const locationCptsOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${tokenProvider.token}`,
                },
                body: JSON.stringify({
                    operationName: "locationCpts",
                    variables: { "filter": "" },
                    query: `query locationCpts($filter: String!) {
                        locationCpts(filter: $filter) {
                            locationId,
                            locationDescription,
                            cptId,
                            cptCode,
                            primaryUnits,
                            secondaryUnits
                        }
                    }`
                })
            };

            let locationCptsResponse = await fetch(api, locationCptsOptions);
            let locationCptsJson = await locationCptsResponse.json();
            locationCpts = locationCptsJson.data.locationCpts;
            // console.log(`locationCpts = `, locationCpts);
        } else {
            console.log(`ALREADY LOADED :: locationCpts = `, locationCpts);
        }
    }

    function applyUnitCap(unitsTotalForVisit, locationName) {
        let finalUnits = unitsTotalForVisit;
        let foundLocation = kareoLocations.find(x => x.practiceName === locationName);
        if (foundLocation && foundLocation.chargeType !== 'Legacy') {
            const locationChargeCap = foundLocation.facilityfees.find(locationfee => locationfee.feeName === "ChargeCap");
            if (locationChargeCap && (parseFloat(`${finalUnits}`) > parseFloat(`${locationChargeCap.amount}`))) {
                finalUnits = parseFloat(`${locationChargeCap.amount}`);
            }
        } else if (foundLocation && foundLocation.chargeType === 'Legacy') {
            if (parseFloat(`${finalUnits}`) > 40) {
                finalUnits = 40;
            }
        }
        return finalUnits;
    }

    function getUnits(ticket, ticketCpt, providerType, locationName, isClinic) {

        // console.log(`kareoLocations = `, kareoLocations);
        // console.log(`locationCpts = `, locationCpts);

        console.log(`[LocationsUnitsProvider] :: getUnits `, ticketCpt);

        let units = 0.0;

        let newLogicSuccess = false;
        const foundLocation = kareoLocations.find(x => x.practiceName === locationName);
        const foundTier = getTier(ticket, foundLocation);

        // first check that the new logic should be used based on the tier
        if (foundLocation && foundTier !== 'Legacy') { // && foundLocation.chargeType !== 'Legacy') {

            if (!ticketCpt) {
                console.log(`ERROR WITH TICKET CPTs`, ticket);
                console.logError(`ERROR WITH TICKET CPTs`, ticket);
            }
            if (!ticketCpt.cpt) {
                console.log(`ERROR WITH TICKET CPTs`, ticket);
                console.logError(`ERROR WITH TICKET CPTs`, ticket);
            }
            // console.log(`DO NEW 2022 LOGIC!!! => ${locationName} | cpt = ${ticketCpt.cpt.code}`, ticket);
            // do the NEW logic... (based on new contracts)

            // first try to apply TIER units
            let allowClinicProcedures = false;
            let cptTier = cptTiers.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && `${x.tierName}` === `${foundTier.tierName}`);
            if (!cptTier) {
                // try to get the DEFAULT unit rate
                // console.log(`Not found in TIER. Looking for default..`);
                cptTier = cptTiers.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && x.tierId === 0);
            }
            if (cptTier) {
                // console.log(`Found cptTier:`, cptTier);
                // this CPT is part of the DEFALT tier
                if (ticketCpt.cpt.code.startsWith('99')) {
                    units = cptTier.primaryUnits;
                } else if (ticketCpt.sequence === 1) {
                    units = cptTier.primaryUnits;
                } else {
                    units = cptTier.secondaryUnits;
                }

                // tier 6 has special handling for Procedures performed at Clinic
                if (`${cptTier.tierId}` === '6') {
                    if (ticketCpt.cptType === 'ClinicProcedure') {
                        allowClinicProcedures = true;
                        if (ticketCpt.sequence === 2) {
                            units = cptTier.primaryUnits;
                        }
                        if (ticketCpt.sequence > 2) {
                            units = cptTier.secondaryUnits;
                        }
                    }
                }

                newLogicSuccess = true;
            }

            // override with location-specific CPTs (if found)
            let locationCpt = locationCpts.find(x => `${x.cptId}` === `${ticketCpt.cpt.id}` && `${x.locationId}` === `${foundLocation.id}`);
            if (locationCpt) {
                // console.log(`Found LocationCPT:`, locationCpt);
                // this CPT has an exception for this location...
                if (ticketCpt.cpt.code.startsWith('99')) {
                    units = locationCpt.primaryUnits;
                } else
                    if (ticketCpt.sequence === 1) {
                        units = locationCpt.primaryUnits;
                    } else {
                        units = locationCpt.secondaryUnits;
                    }
                newLogicSuccess = true;
            }

            // don't charge for "ordered proceures" (i.e. from PAs doing a Clinic visit)
            // HOWEVER, tier 6 ClinicProcedures are charged (and are performed by PA or NP)
            // BUT unlike legacy computation, some facilities are charged for clinica visits (i.e. when IsClinic==true)
            if (providerType === 'PA' && !allowClinicProcedures) {
                if (!ticketCpt.cpt.code.startsWith('99')) {
                    // console.log(`Provider is 'PA' so units = 0`);
                    units = 0;
                }
            }

            // don't charge for "ordered proceures" (sequence != 1) during a Clinic Visit
            // HOWEVER, tier 6 ClinicProcedures are charged (and are performed by PA or NP)
            if (isClinic && ticketCpt.sequence !== 1 && !ticketCpt.cpt.code.startsWith('99') && !allowClinicProcedures) {
                // console.log(`sequence is (${ticketCpt.sequence}) and does not start with '99' so units = 0`);
                units = 0;
            }

            // never charge for training
            if (
                locationName.toLowerCase().includes('training')
                || locationName.toLowerCase().includes('test')
                || ticket.patientName.toLowerCase().includes('training')
                || ticket.patientName.toLowerCase().includes('test')
            ) {
                // console.log(`'HPM Training' so units = 0`);
                units = 0;
            }

            const locationChargeCap = foundLocation.facilityfees.find(locationfee => locationfee.feeName === "ChargeCap");
            if (locationChargeCap && (units > locationChargeCap.amount)) {
                console.log(`Applying charge cap (units => ${locationChargeCap.amount}) for ${foundLocation.practiceName}`);
                units = locationChargeCap.amount;
            }
        }

        if (!newLogicSuccess) {
            // console.log(`DO OLD LEGACY LOGIC!!! => ${locationName} | cpt = ${ticketCpt.cpt.code}`, ticketCpt);
            // do the LEGACY logic...

            // never charge for training
            if (locationName.toLowerCase().includes('training') || ticket.patientName.toLowerCase().includes('training') || locationName.toLowerCase().includes('test')) {
                return 0;
            }
            if (isClinic || providerType === 'PA' || locationName.toLowerCase().includes('training') || ticket.patientName.toLowerCase().includes('training')) {
                if (locationName !== 'Rawlins')
                    return parseFloat(`${units}`);
                else if (ticketCpt.cpt.code.startsWith('99'))
                    return parseFloat(`${ticketCpt.cpt.primaryUnits}`);
            }
            else if (ticketCpt.sequence === 1) {
                units = parseFloat(`${ticketCpt.cpt.primaryUnits}`);
            }
            else {
                units = parseFloat(`${ticketCpt.cpt.secondaryUnits}`);
            }
            unitRateExpections.map((exception) => {
                if (exception.location === locationName && exception.code === ticketCpt.cpt.id) {
                    units *= exception.rate;
                }
                return false;
            });
        }

        // console.log(`get UNITS = ${units} => ${locationName} | cpt = ${ticketCpt.cpt.code}`);
        console.log(`[LocationsUnitsProvider] :: get UNITS = ${units} => ${locationName} | cpt = ${ticketCpt.cpt.code}`);
        return Number(units);
    }

    function getTier(ticket, location) {
        if (!location) { return 'Legacy'; }
        if (location.tiers.length === 0) { return 'Legacy'; }

        let ticketDate = new Date(`${ticket.date}`);

        // is ticket in active tier? (end date == null)
        let activeTier = location.tiers.find(x => !x.endDate);
        if (!activeTier) { return 'Legacy'; }

        let startDate = new Date(`${activeTier.startDate}`);
        if (ticketDate > startDate) { return activeTier; }

        // ticket not in active tier, and no other tier exists...
        if (location.tiers.length === 1) { return 'Legacy'; }

        // get the tier the ticket is in based on start/end dates
        activeTier = location.tiers.filter(x => x.endDate)
            .find(x =>
                ticketDate < new Date(`${x.endDate}`)
                && ticketDate > new Date(`${x.startDate}`));

        if (activeTier) { return activeTier; }

        // throw `Cannot find tier for ticket with date: ${ticketDate}`;
        return 'Legacy';
    }

    return {
        // getInstance: getInstance,
        refreshData: refreshData,
        getUnits: getUnits,
        applyUnitCap: applyUnitCap
    };
})();
