const { api } = require('../env/connection');
const { tokenProvider } = require('./tokenProvider');
const { chunk } = require('../utils/arrayUtils');
const { isLoggedIn } = require('../services/auth');

class ChargeClient {

    constructor() { }

    async getAppointments(filter) {

        try {
            console.log(`[ChargeClient] :: getAppointments :: ${api}/api/appointments/find`);
            let response = await fetch(`${api}/api/appointments/find`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(filter)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            let appointmentsResponse = await response.json();
            console.log(`[ChargeClient] :: fetched appointments => `, appointmentsResponse);

            appointmentsResponse.data.appointments.forEach(appt => {

                // let fixedDate = appt.date.replace('Z', '-08:00');
                let fixedDate = appt.localDate.replace('Z', '-08:00');
                appt.utcDate = new Date(Date.parse(fixedDate));

                appt.date = new Date(`${appt.date}`);
            });

            console.log(`[ChargeClient] :: fetched appointments AFTER => `, appointmentsResponse);

            return appointmentsResponse.data.appointments;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to get appointments => `, err);
            throw new Error(`Failed to get appointments`);
        }
    }

    async saveTicket(ticket) {
        const url = `${api}/api/charge-tickets/save`;
        try {
            console.log(`[ChargeClient] :: saveTicket :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(ticket)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const ticketsResponse = await response.json();
            console.log(`[ChargeClient] :: saved ticket => `, ticketsResponse);
            return ticketsResponse.data.tickets.length ? ticketsResponse.data.tickets[0] : null;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to save ticket => `, err);
            throw new Error(`Failed to save ticket`);
        }
    }

    async getTickets(filter) {

        try {
            console.log(`[ChargeClient] :: getTickets :: ${api}/api/charge-tickets/find`);
            const response = await fetch(`${api}/api/charge-tickets/find`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(filter)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const ticketsResponse = await response.json();
            console.log(`[ChargeClient] :: fetched tickets => `, ticketsResponse);
            return ticketsResponse.data.tickets;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to get tickets => `, err);
            throw new Error(`Failed to get tickets`);
        }
    }

    async saveAppointments(appointments, start, end) {
        let result = [];
        // split into chunks for saving
        const chunks = chunk(appointments, 500);
        console.log(`save appts => ${chunks.length} chunks...`);
        for (const appts of chunks) {
            let startDate = new Date(appts[0].date);
            let endDate = new Date(appts[appts.length - 1].endDate);
            console.log(`   start=${startDate} | end=${endDate}`);
            let response = await this._saveAppointments(appts, startDate, endDate); // , start, end);
            if (response.data.appointments.length) {
                // console.log(`saved appts = `, response.data.appointments);
                result.push.apply(result, response.data.appointments);
            }
            if (response.errors.length) {
                console.log(`  error appts = `, response.errors);
            }
        }
        return result;
    }

    async syncPatients(patients) {
        let result = [];
        // split into chunks for saving
        const chunks = chunk(patients, 500);
        console.log(`save pts => ${chunks.length} chunks...`);
        for (const pts of chunks) {
            const hpmPatients = pts.map(x => {
                return {
                    id: x.ID,
                    createdDate: x.CreatedDate,
                    lastModifiedDate: x.LastModifiedDate,
                    firstName: x.FirstName,
                    lastName: x.LastName,
                    practiceName: x.PracticeName,
                    homePhone: x.HomePhone,
                    workPhone: x.WorkPhone,
                    mobilePhone: x.MobilePhone,
                    emailAddress: x.EmailAddress,
                    practiceId: `${x.PracticeId}`,
                    state: x.State,
                    address: x.AddressLine2 ? `${x.AddressLine1}, ${x.AddressLine2}` : `${x.AddressLine1}`,
                    city: x.City,
                    zip: x.ZipCode
                }
            });
            let response = await this._savePatients(hpmPatients);
            if (response.data.patients.length) {
                // console.log(`saved patients = `, response.data.patients);
                result.push.apply(result, response.data.patients);
            }
            if (response.errors.length) {
                console.log(`  error patients = `, response.errors);
            }
        }
        return result;
    }

    async saveTicketChangeRequest(request) {
        try {
            const url = `${api}/api/charge-tickets/change-requests/save`;
            console.log(`[ChargeClient] :: saveTicketChangeRequest :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(request)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const changeResponse = await response.json();
            console.log(`[ChargeClient] :: saved change request response => `, changeResponse);
            return changeResponse.data.requests;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to save change request => `, err);
            throw new Error(`Failed to save change request`);
        }
    }

    async findTicketChangeRequests(filter) {
        try {
            const url = `${api}/api/charge-tickets/change-requests/find`;
            console.log(`[ChargeClient] :: findTicketChangeRequests :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(filter)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const changeResponse = await response.json();
            console.log(`[ChargeClient] :: find change request response => `, changeResponse);
            return changeResponse.data.requests;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to find change request => `, err);
            throw new Error(`Failed to find change request`);
        }
    }

    async getCpts() {
        try {
            const url = `${api}/api/charge-tickets/cpts`;
            console.log(`[ChargeClient] :: getCpts :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                }
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const changeResponse = await response.json();
            console.log(`[ChargeClient] :: find CPTs response => `, changeResponse);
            return changeResponse.data.cpts;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to find CPTs => `, err);
            throw new Error(`Failed to find CPTs`);
        }
    }

    async getIcdCodes() {
        try {
            const url = `${api}/api/icds/find`;
            console.log(`[ChargeClient] :: getIcdCodes :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                }
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const icdResponse = await response.json();
            console.log(`[ChargeClient] :: find ICD-10 Codes response => `, icdResponse);
            return icdResponse.data.icdCodes;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to find ICD-10 Codes => `, err);
            throw new Error(`Failed to find ICD-10 Codes`);
        }
    }

    async lockTickets(filter) {
        try {
            const url = `${api}/api/charge-tickets/lock`;
            console.log(`[ChargeClient] :: lockTickets :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(filter)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const changeResponse = await response.json();
            console.log(`[ChargeClient] :: lockTickets response => `, changeResponse);
            return changeResponse.data.count;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to lock Tickets => `, err);
            throw new Error(`Failed to lock Tickets`);
        }
    }

    async unlockTickets(filter) {
        try {
            const url = `${api}/api/charge-tickets/unlock`;
            console.log(`[ChargeClient] :: unlockTickets :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: JSON.stringify(filter)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const changeResponse = await response.json();
            console.log(`[ChargeClient] :: unlockTickets response => `, changeResponse);
            return changeResponse.data.count;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to unlock Tickets => `, err);
            throw new Error(`Failed to unlock Tickets`);
        }
    }

    async findLocations(filter) {
        // locations/find
        try {
            const url = `${api}/api/locations/find`;
            console.log(`[ChargeClient] :: findLocations :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                },
                body: filter ? JSON.stringify(filter) : null
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const locationsReponse = await response.json();
            console.log(`[ChargeClient] :: findLocations response => `, locationsReponse);
            return locationsReponse.data.locations;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to find Locations => `, err);
            throw new Error(`Failed to find Locations`);
        }
    }

    async getPermissions() {
        if (!isLoggedIn()) { return; }
        try {
            const url = `${api}/api/auth/permissions`;
            console.log(`[ChargeClient] :: getPermissions :: ${url}`);
            const response = await fetch(`${url}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenProvider.token}`
                }
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            const permissionsResponse = await response.json();
            console.log(`[ChargeClient] :: getPermissions response => `, permissionsResponse);
            return permissionsResponse.data.permissions;

        } catch (err) {
            console.error(`[ChargeClient] :: ERROR: Failed to get permissions => `, err);
            throw new Error(`Failed to get permissions`);
        }
    }

    async _savePatients(patients) {

        console.log(`pts[0] = `, patients[0]);
        // console.log(`pts = `);
        // patients.forEach(x => console.log(x));

        let url = `${process.env.CHARGE_API_URL}/api/patients/sync`;

        try {
            // console.log(`_saveAppointments :: ${process.env.CHARGE_API_URL}/api/appointments/save with API KEY = ${process.env.CHARGE_API_KEY}`);
            console.log(`_savePatients :: ${url} ...`);
            let response = await fetch(url, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${process.env.CHARGE_API_KEY}`
                },

                //make sure to serialize your JSON body
                body: JSON.stringify(patients)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            let patientsResponse = await response.json();

            return patientsResponse;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to save appointments => `, err);
            throw new Error(`Failed to save patients`);
        }

    }

    async _saveAppointments(appointments, start, end) {

        console.log(`appt[0] = `, appointments[0]);
        // console.log(`appts = `);
        // appointments.forEach(x => console.log(x));

        appointments.forEach(appt => {
            if (appt.endDate) { delete appt.endDate; }
        });

        let url = `${process.env.CHARGE_API_URL}/api/appointments/save`;
        if (start && end) {
            url += `?start=${start.toISOString()}&end=${end.toISOString()}`;
        }

        try {
            // console.log(`_saveAppointments :: ${process.env.CHARGE_API_URL}/api/appointments/save with API KEY = ${process.env.CHARGE_API_KEY}`);
            console.log(`_saveAppointments :: ${url} ...`);
            let response = await fetch(url, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${process.env.CHARGE_API_KEY}`
                },

                //make sure to serialize your JSON body
                body: JSON.stringify(appointments)
            });

            if (response.status >= 400 && response.status < 600) {
                console.log(`Bad response from server (${response.status} | ${response.statusText}) => `, response);
                throw new Error(`Bad response from server (${response.status} | ${response.statusText})`);
            }

            let appointmentsResponse = await response.json();

            return appointmentsResponse;

        } catch (err) {
            console.log(`[ChargeClient] :: ERROR: Failed to save appointments => `, err);
            throw new Error(`Failed to save appointments`);
        }

    }

}

module.exports.ChargeClient = ChargeClient;