module.exports.chunk = (items, size) => {
    const chunks = [];
    items = [].concat(...items);

    while (items.length) {
        chunks.push(
            items.splice(0, size)
        );
    }

    return chunks;
}

// Generic helper function that can be used for the three operations:        
const operation = (list1, list2, key, isUnion = false) =>
    list1.filter(
        (set => a => isUnion === set.has(a[`${key}`]))(new Set(list2.map(b => b[`${key}`])))
    );

// Following functions are to be used:
const inBoth = (list1, list2, key) => operation(list1, list2, key, true);
const inFirstOnly = operation;
const inSecondOnly = (list1, list2, key) => inFirstOnly(list2, list1, key);

module.exports.inBoth = inBoth;
module.exports.inFirstOnly = inFirstOnly;
module.exports.inSecondOnly = inSecondOnly;

const groupBy = (array, key) => // https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
module.exports.groupBy = groupBy;

const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}
module.exports.merge = merge;