import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const isBrowser = () => typeof window !== "undefined";

export default function IdleDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [remainingTime, setRemainingTime] = React.useState(30);

  let isPromptOpen = false;

  const handleClickOpen = () => {
    setRemainingTime(30);
    isPromptOpen = true;
    setOpen(true);    
  };

  if (isBrowser()) {
    window.showIdleDialog = handleClickOpen;
  }  

  const handleClose = () => {
    setRemainingTime(30);
    setOpen(false);
    if (isBrowser()) {
      window.resetIdleTime();
    }
  };
  if (isBrowser()) {
    window.hideIdleDialog = handleClose;
  }  

  useEffect(() => {
    const interval = setInterval(() => {
        console.log(`remainingTime = ${remainingTime}`);
        if (remainingTime > 0) {
            setRemainingTime(remainingTime => remainingTime - 1);
        }
    }, 1000);
    return () => {
        clearInterval(interval);
    }
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you still there?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If not, you will be logged out automatically after <strong>{ remainingTime }</strong> seconds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            I'm Still Here
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}