import React, { useEffect } from "react";
import { Button, IconButton, ListItem, MenuList, Paper, Popper } from '@material-ui/core/';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { Notifications } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
// import { Link, navigate } from "gatsby";
import { navigate } from "@reach/router";
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { getUser, isLoggedIn } from "../../services/auth";
import { STORED_NOTIFICATIONS, DISMISSED_NOTIFICATIONS } from '../../constants';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import './nav.css';
import NotificationProvider from "../../providers/notification-provider";
import { ChargeEvents, EventBus } from "../../providers/event-bus";
import PermissionsProvider, { HPM_ROLE } from "../../providers/permissions-provider";

const today = new Date();

const tenDaysAgo = new Date();
tenDaysAgo.setDate(today.getDate() - 30);
tenDaysAgo.setHours(0, 0, 0, 1);

const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);
yesterday.setHours(23, 59, 59, 0);

const notificationProvider = new NotificationProvider();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const NotificationBell = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);

    // appointments
    const [apptNotifications, setApptNotifications] = React.useState([]);
    const [displayedApptNotifications, setDisplayedApptNotifications] = React.useState([]);
    const [apptDisplayCount, setApptDisplayCount] = React.useState(10);

    // change requests
    const [ticketNotifications, setTicketNotifications] = React.useState([]);

    useEffect(() => {
        if (isLoggedIn()) {
            setupNotifications();
            EventBus.on(ChargeEvents.SAVED, (data) => {
                setupNotifications({
                    updateType: ChargeEvents.SAVED,
                    ticket: data
                });
            });
            EventBus.on(ChargeEvents.EDITED, (data) => {
                // console.log(`Charge ticket edited: `, data);
                setupNotifications({
                    updateType: ChargeEvents.EDITED,
                    ticket: data
                });
            });
        }
    }, []);

    useEffect(() => {
        setDisplayedApptNotifications(apptNotifications.slice(0, apptDisplayCount));
    }, [apptDisplayCount]);

    const setupNotifications = async (updateData) => {
        console.log(`Setting up notifications...`);
        const user = getUser(); //.kareoId; // testUserKareoId; // getUser().kareoId;
        let notificationsItems = await notificationProvider.generateApptNotifications(user, tenDaysAgo, yesterday);
        if (notificationsItems && notificationsItems.length) {
            if (updateData) { // handle removing data for a ticket that was just created (appt sync can take up to 15 mins from Kareo)
                try {
                    let foundExistingNotif = notificationsItems.find(x => `${x.data.kareoId}` === `${updateData.ticket.kareoApptId}`);
                    if (foundExistingNotif) { dismissNotification(foundExistingNotif.id); }
                } catch { }
                notificationsItems = notificationsItems.filter(x => `${x.data.kareoId}` !== `${updateData.ticket.kareoApptId}`);
            }
            setApptNotifications(notificationsItems);
            setDisplayedApptNotifications(notificationsItems.slice(0, apptDisplayCount));
        }

        if (PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) {
            let changeRequestItems = await notificationProvider.generateTicketRequestNotifications();
            setTicketNotifications(changeRequestItems);
        }

    };

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowMore = () => {
        setApptDisplayCount(prevCount => prevCount + 10);
    };


    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const dismissNotification = (notificationId) => {
        console.log(`dismissing noticiation with ID = ${notificationId}`);
        // return;
        const dismissedNotifications = localStorage.getItem(DISMISSED_NOTIFICATIONS) ? JSON.parse(localStorage.getItem(DISMISSED_NOTIFICATIONS)) : [];

        const expiration = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
        dismissedNotifications.push({ id: notificationId, expires: `${expiration.toISOString()}` });
        dismissedNotifications.sort((a, b) => a.id - b.id);
        localStorage.setItem(DISMISSED_NOTIFICATIONS, JSON.stringify(dismissedNotifications));

        const updatedNotifications = apptNotifications.filter(notification => notification.id !== notificationId);
        localStorage.setItem(STORED_NOTIFICATIONS, JSON.stringify(updatedNotifications));
        setApptNotifications(updatedNotifications);
        setDisplayedApptNotifications(updatedNotifications.slice(0, apptDisplayCount));
    }

    const fillChargeTicket = (e, notification) => {
        e.stopPropagation();
        e.preventDefault();
        handleClose();
        console.log(`Setting up charge ticket with data: `, notification);
        if (window.location.href.includes('/app/chargesheet')) {
            navigate('/app/create-charge', { state: notification, replace: true });
        } else if (window.location.href.includes('/app/create-charge')) {
            navigate('/app/chargesheet', { state: notification, replace: true });
        }

        // window.location.reload();
        // navigate('/app/chargesheet', { state: notification, replace: true });
    };

    const viewRequest = (e, notification) => {
        e.stopPropagation();
        e.preventDefault();
        handleClose();
        console.log(`Setting up charge ticket with data: `, notification);
        navigate(`/app/ticket-update-request/${notification.id}`);
    };


    return (
        <>
            <Badge badgeContent={displayedApptNotifications.length || ticketNotifications.length} color="secondary">
                <Notifications onClick={handleClick} />
            </Badge>

            {` | `}

            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} disablePortal={false} placement='bottom-start' style={{ background: 'white', zIndex: 999 }}>
                <Paper elevation={3}>
                    <div className="notifications-header">
                        <h3>Notifications</h3>
                        <IconButton onClick={handleClose}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab {...a11yProps(0)}
                            label={<Badge badgeContent={displayedApptNotifications.length} color="secondary">
                                Patients
                            </Badge>}
                        />
                        <Tab {...a11yProps(1)}
                            label={<Badge badgeContent={ticketNotifications.length} color="secondary">
                                Change Requests
                            </Badge>}
                        />
                        <Tab label="Announcements" {...a11yProps(2)} />
                    </Tabs>

                    <Divider />

                    <TabPanel value={tabIndex} index={0} component="div">
                        <MenuList autoFocusItem={Boolean(anchorEl)} id="notification-list-grow" className="notifications-list">
                            {displayedApptNotifications && displayedApptNotifications.length > 0 && displayedApptNotifications.map(notification => {
                                return (
                                    <ListItem key={notification.id} onClick={(evt) => fillChargeTicket(evt, notification.data)} className="notifications-list-item">
                                        <div className="patient-notification-info">
                                            <h3>{notification.title}</h3>
                                            <p>{notification.patient}</p>
                                            <p>{notification.data.location}</p>
                                            <p>{notification.displayDate}</p>
                                        </div>
                                        <Tooltip title="Create ticket">
                                            <IconButton onClick={(evt) => fillChargeTicket(evt, notification.data)}>
                                                <NoteAddOutlinedIcon fontSize="small" color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Dismiss">
                                            <IconButton onClick={() => dismissNotification(notification.id)}>
                                                <DeleteOutlinedIcon fontSize="small" color="secondary" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItem>
                                )
                            })}
                            {apptNotifications && displayedApptNotifications && (apptNotifications.length > displayedApptNotifications.length) && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button color="primary" onClick={handleShowMore}>Show More</Button>
                                </div>

                            )}

                            {displayedApptNotifications && displayedApptNotifications.length === 0 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    No notifications.
                                </div>
                            )}
                        </MenuList>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1} component="div">
                        <MenuList autoFocusItem={Boolean(anchorEl)} id="notification-list-grow" className="notifications-list">
                            {ticketNotifications && ticketNotifications.length > 0 && ticketNotifications.map(notification => {
                                return (
                                    <ListItem key={notification.id} onClick={(evt) => viewRequest(evt, notification)} className="notifications-list-item">
                                        <div className="patient-notification-info">
                                            <h3>Ticket Change Request</h3>
                                            <p>Request by: {notification.requestByUser.firstName} {notification.requestByUser.lastName}</p>
                                            <p>Request date: {new Date(notification.requestDate).toLocaleDateString('en-US')}</p>
                                            <p>Ticket ID: {notification.ticketData.id}</p>
                                        </div>
                                        <Tooltip title="View request">
                                            <IconButton onClick={(evt) => viewRequest(evt, notification)}>
                                                <AssignmentTurnedInIcon fontSize="small" color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        {/* 
                                        <Tooltip title="Dismiss">
                                            <IconButton onClick={() => dismissNotification(notification.id)}>
                                                <DeleteOutlinedIcon fontSize="small" color="secondary" />
                                            </IconButton>
                                        </Tooltip> */}
                                    </ListItem>
                                )
                            })}

                            {ticketNotifications && ticketNotifications.length === 0 && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    No notifications.
                                </div>
                            )}
                        </MenuList>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2}>
                        <MenuList autoFocusItem={Boolean(anchorEl)} id="notification-list-grow" className="notifications-list">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                No notifications.
                            </div>
                        </MenuList>
                    </TabPanel>


                </Paper>
            </Popper>
        </>
    )
}

export default NotificationBell;