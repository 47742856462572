import React from "react";
import { Link } from "gatsby";
import { navigate } from "@reach/router"

import { getUser, logout } from "../../services/auth";

import './nav.css';
import NotificationBell from "../notifications/notification-bell";
import PermissionsProvider, { HPM_PERMISSIONS } from "../../providers/permissions-provider";

const LoggedInNav = () => {

    const user = getUser();

    return (
        <>
            {/* <Link to="/app/outreach" >Outreach</Link>{` | `} */}
            {/* <Link to="/app/hpm-cdm" >HPM CDM</Link>{` | `} */}
            {/* <Link to="/app/analytics" >Analytics</Link>{` | `} */}
            {/* <Link to="/app/financeoutreach" >Finance</Link>{` | `} */}

            <NotificationBell></NotificationBell>
            
            {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.CHARGE_TICKETS_VIEW) && <><Link to="/app/ticketview" >View Tickets</Link>{` | `}</>}
            {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.REPORTS_VIEW) && <><Link to="/app/reports" >Reports</Link>{` | `}</>}
            {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.DASHBOARDS_VIEW) && <><Link to="/app/dashboards" >Dashboards</Link>{` | `}</>}
            {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.SCHEDULE_VIEW) && <><Link to="/app/schedule" >Schedule</Link>{` | `}</>}
            {PermissionsProvider.hasPermission(user, HPM_PERMISSIONS.USERS_VIEW) && <><Link to="/app/users" >Users</Link>{` | `}</>}
            {/* <Link to="/app/hpm-cdm" >CPT Codes</Link>{` | `} */}
            <a
                href="/"
                onClick={event => {
                    event.preventDefault();
                    const params = new URLSearchParams(window.location.search);
                    const override = params.get('override');
                    logout(() => navigate(override ? `/app/login?override=${override}` : `/app/login`));
                }}
            >
                Logout
            </a>
        </>
    )
}

export default LoggedInNav