
export const HPM_ROLE = Object.freeze({
    Corporate: 'Corporate',
    Clinic: 'Clinic',
    Procedure: 'Procedure',
    Training: 'Training',
    Finance: 'Finance',
    Operations: 'Operations',
    Education: 'Education',
    Nursing: 'Nursing',
    Equipment: 'Equipment',
    Regional: 'Regional',
    Scheduling: 'Scheduling',
    SLT: 'SLT',
    HIM_RCM: 'HIM-RCM'
});

export const HPM_PERMISSIONS = Object.freeze({
    // system
    USERS_VIEW: 'hpm.system.users.view',
    USERS_EDIT: 'hpm.system.users.edit',
    // UI
    CHARGE_TICKETS_VIEW: 'hpm.charge.tickets.page.view',
    // reports
    REPORTS_VIEW: 'hpm.charge.reports.page.view',
    REPORTS_CASE_LOG: 'hpm.charge.reports.case-log.view',
    REPORTS_CASE_LOG_PROVIDERS: 'hpm.charge.reports.case-log.providers',
    REPORTS_CHARGE_TICKETS: 'hpm.charge.reports.charge-tickets.view',
    REPORTS_CHARGE_TICKETS_PROVIDERS: 'hpm.charge.reports.charge-tickets.providers',
    REPORTS_DISCREPANCY: 'hpm.charge.reports.discrepancy.view',
    REPORTS_ITEMIZED_BILL: 'hpm.charge.reports.itemized-bill.view',
    REPORTS_CMR: 'hpm.charge.reports.contribution-margin.view',
    REPORTS_APPTS: 'hpm.charge.reports.appointments.view',
    REPORTS_PATIENTS: 'hpm.charge.reports.patients.view',
    REPORTS_CALL_LOG: 'hpm.charge.reports.call-log.view',
    REPORTS_COMPARISON: 'hpm.charge.reports.comparison.view',
    REPORTS_REFERRED_PROVIDERS: 'hpm.charge.reports.referred-providers.view',
    REPORTS_ORDER_COMPLEXITY_PROVIDERS: 'hpm.charge.reports.order-complexity.view',
    // dashboards
    DASHBOARDS_VIEW: 'hpm.charge.dashboards.page.view',
    DASHBOARDS_OPS: 'hpm.charge.dashboards.operations.view',
    DASHBOARDS_EXECUTIVE: 'hpm.charge.dashboards.executive.view',
    DASHBOARDS_REGIONAL: 'hpm.charge.dashboards.regional.view',
    DASHBOARDS_CARECOORDINATOR: 'hpm.charge.dashboards.carecoordinator.view',
    // schedule
    SCHEDULE_VIEW: 'hpm.charge.schedule.page.view'
});

// todo: this list should pull from API
export const roleOptions = [
    {
        id: 1,
        name: 'Corporate'
    },
    {
        id: 2,
        name: 'Clinic'
    },
    {
        id: 3,
        name: 'Procedure'
    },
    {
        id: 4,
        name: 'Training'
    },
    {
        id: 5,
        name: 'Finance'
    },
    {
        id: 6,
        name: 'Operations'
    },
    {
        id: 7,
        name: 'Education'
    },
    {
        id: 8,
        name: 'Nursing'
    },
    {
        id: 9,
        name: 'Equipment'
    },
    {
        id: 10,
        name: 'Regional'
    },
    {
        id: 11,
        name: 'Scheduling'
    },
    {
        id: 12,
        name: 'SLT'
    },
    {
        id: 13,
        name: 'HIM-RCM'
    },
    {
        id: 14,
        name: 'Tester'
    },
    {
        id: 15,
        name: 'Care Coordinator'
    },
];

export default class PermissionsProvider {

    static #permissionsList = [];

    static _setPermissionsList(list) {
        this.#permissionsList = list;
    }

    static hasRole (user, role) {
        // console.log(`[PermissionsProvider] :: hasRole? ${role} => `, user);
        if (!user) {return false;}
        if (!user.roles) {return false;}
        let matchingRole = user.roles.find(x => x.name === role);
        return !!matchingRole;
    }

    static hasRoles (user, roles) {
        if (!user) {return false;}
        if (!user.roles) {return false;}
        let userRoles = user.roles.map(x => x.name);
        let matchingRole = roles.every(x => userRoles.includes(x));
        return !!matchingRole;
    }

    static hasAnyRoles (user, roles) {
        if (!user) {return false;}
        if (!user.roles) {return false;}
        let userRoles = user.roles.map(x => x.name);
        let matchingRole = roles.some(x => userRoles.includes(x));
        return !!matchingRole;
    }

    static hasRoleId (user, roleId) {
        if (!user) {return false;}
        if (!user.roles) {return false;}
        let matchingRole = user.roles.find(x => x.id === roleId);
        return !!matchingRole;
    }

    static hasRoleIds (user, roleIds) {
        if (!user) {return false;}
        if (!user.roles) {return false;}
        let userRoleIds = user.roles.map(x => x.id);
        let matchingRole = roleIds.every(x => userRoleIds.includes(x));
        return !!matchingRole;
    }

    static hasPermission(user, permissionCode) {
        if (!user) {return false;}
        if (!user.permissions) {return false;}
        let matchingPermission = user.permissions.find(x => x.code === permissionCode);
        console.log(`[PermissionsProvider] :: Checking for permission (${permissionCode}) => ${matchingPermission} | ${!!matchingPermission}`, user);
        return !!matchingPermission;
    }

    static hasPermissions(user, permissionCodes) {
        if (!user) {return false;}
        if (!user.permissions) {return false;}
        let userPermissionCodes = user.permissions.map(x => x.code);
        let matchingPermission = permissionCodes.every(x => userPermissionCodes.includes(x));
        return !!matchingPermission;
    }
}