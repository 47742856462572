import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import LoggedInNav from "./nav/loggedInNav.js";
import Survey from "./survey";
import { SURVEY_KEY } from "../constants";
import { Button, Snackbar } from "@mui/material"

const Header = ({ siteTitle, version }) => {
    const [disableSurvey, setDisableSurvey] = useState(false);

    const disableSurveyBanner = () => {
        if (typeof window !== "undefined") {
            localStorage.setItem(SURVEY_KEY, true);
            setDisableSurvey(true);
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            setDisableSurvey(localStorage.getItem(SURVEY_KEY));
        }
    }, []);

    //     const data = useStaticQuery(graphql`
    //     query {
    //       placeholderImage: file(relativePath: { eq: "HPM Alt Logo White.png" }) {
    //         childImageSharp {
    //           fixed(width: 22, height: 32) {
    //             ...GatsbyImageSharpFixed
    //           }
    //         }
    //       }
    //     }
    //   `)

    let envColor = '#0171B9';
    let envName = '';

    if (typeof window !== "undefined") {
        if (window.location.href.indexOf('test.hpmcharge.com') > -1) {
            envName = 'TEST';
            // envColor = '#ed2127';
        }
        if (window.location.href.indexOf('dev.hpmcharge.com') > -1) {
            envName = 'DEV';
            // envColor = '#ed2127';
        }
        if (window.location.href.indexOf('localhost') > -1) {
            envName = 'LOCAL';
            // envColor = '#ed2127';
        }
    }


    let greetingMessage = ""
    if (isLoggedIn()) {
        greetingMessage = '';//`Hello, ${getUser() && getUser().firstName} `
    } else {
        greetingMessage = "Log In"
    }

    return (
        <header style={{ background: `${envColor}`, marginBottom: `16px` }} >
            {envName && <div style={{ padding: '4px', background: '#ed2127', color: 'white', minWidth: '80px', justifyContent: 'center', textAlign: 'center', fontSize: '0.8rem', height: '24px', lineHeight: '16px' }}>{envName}</div>}
            <nav className="flex justify-between px-2 py-2" style={{ color: `white`, textDecoration: `none`, margin: `0 auto` }} >
                <div style={{display: 'flex'}}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1 style={{ margin: 0 }}>
                            <Link to="/app/chargesheet" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <Img fixed={data.placeholderImage.childImageSharp.fixed} /> {siteTitle} */}
                                <img style={{ width: 22, height: 32, margin: 0, marginRight: 8 }} src={require('../images/HPM Alt Logo White.png').default} />
                                {siteTitle} {/* ({envName}) */}
                            </Link>
                        </h1>
                        {/* <div style={{ padding: '8px', background: '#ed2127', color: 'white', borderRadius: '8px', minWidth: '80px', textAlign: 'center', marginLeft: '32px', fontSize: '0.8rem', height: '32px', lineHeight: '16px' }}>{envName}</div> */}
                    </div>
                    {version ?
                        <div style={{ fontSize: '12px', alignSelf: 'flex-end', marginLeft: '8px'}}>
                            {`v.${version}`}
                        </div>
                    : null}
                </div>
                <div className="mt-4" style={{ marginBottom: 0 }}>
                    <span className="mr-4">{greetingMessage}</span>
                    {isLoggedIn() ? <LoggedInNav/> : null}
                </div>
            </nav>
            {isLoggedIn() && !disableSurvey ? <Survey disableSurveyBanner={disableSurveyBanner}/> : null}
            {/* {isLoggedIn() && <NewAppBanner/>} */}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={true}
                message="HPM Charge will be moving to HPM Cloud by 10/13."
                action={
                    <Button variant="contained" color="primary" disableElevation size="small" href="https://charge.hpmcloud.com" target="_blank">
                        Try It Now
                    </Button>
                }
            />
            
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
