import { gql } from "apollo-boost";
import { navigate } from "gatsby";
import { API_KEY, STORED_NOTIFICATIONS } from "../constants";
import { handleNavAfterLogin } from "../components/login";


export const isBrowser = () => typeof window !== "undefined";

const setUser = user => {
    if (isBrowser()) {
        window.localStorage.setItem("hpmUser", JSON.stringify(user));
    }
}

export const getUser = (isLogin) => {
    if (isBrowser() && window.localStorage.getItem("hpmUser")) {
        var cachedUser = JSON.parse(window.localStorage.getItem("hpmUser"));
        if (cachedUser.roles) {
            cachedUser.roles.sort((a,b) => a.id - b.id);
            cachedUser.role = cachedUser.roles[0].id;
        }        
        if (isLogin) {
            return cachedUser;
        }
        if (!cachedUser.isActive || cachedUser.isLocked || cachedUser.requirePasswordReset) {
            // navigate(`/app/login`);
            return null;
        } else {
            return cachedUser;
        }
    }
    return null;
}


export const handleLogin = async (data, client) => {
    let { username, password } = data;
    // console.log(`login with ${username} | ${password}`, data);
    return await client.mutate({
        mutation: gql`
            mutation login ($email: String!, $password: String!) {
                login (email: $email, password: $password) {
                    token
                    user {
                        id
                        firstName
                        lastName
                        email
                        passwordDate
                        kareoId
                        roles {
                            id
                            name
                            rolePermissions {
                                # role {
                                #     id
                                #     name
                                # }
                                permission {
                                    id
                                    code
                                    description
                                }
                            }                            
                        }
                        permissions {
                            id
                            code
                            description
                        }
                        isActive
                        isLocked
                        requirePasswordReset
                    }
                }
            }
        `,
        variables: {
            email: username,
            password: password
        }
    })
        .then(results => {
            // console.log(`user login result = `, results);
            const usr = {
                id: results.data.login.user.id,
                username: results.data.login.user.email,
                firstName: results.data.login.user.firstName,
                lastName: results.data.login.user.lastName,
                passwordDate: results.data.login.user.passwordDate,
                kareoId: results.data.login.user.kareoId,
                role: results.data.login.user.roles[0].id, // setting single role only
                roles: results.data.login.user.roles,

                isActive: results.data.login.user.isActive,
                isLocked: results.data.login.user.isLocked,
                requirePasswordReset: results.data.login.user.requirePasswordReset,

                permissions: results.data.login.user.permissions,

                timestamp: new Date()
            };
            usr.roles.sort((a,b) => a.id - b.id);
            usr.role = usr.roles[0].id;
            setUser(usr);

            // console.log(`auth => user = `, results.data.login.user);

            localStorage.setItem(`hpm.charge.pw.attempts`, `${0}`);
            localStorage.setItem(API_KEY, results.data.login.token);

            if (results.data.login.user.isActive && !results.data.login.user.requirePasswordReset && !results.data.login.user.isLocked) {
                // navigate(`/app/chargesheet`);
                handleNavAfterLogin();
            }

            return results;
        })
        .catch(error => {
            console.log("Error: ", error)
            return error
        })
}
export const updatePassword = async ({ userId, newPassword }, client) => {
    return await client.mutate({
        mutation: gql`
        mutation updateUser($user: UserUpdateInput!) {
            updateUser(user: $user) {
                id
            }
        }
        `,
        variables: {
            user: {
                id: userId,
                password: newPassword,
                passwordDate: new Date(),
                isActive: true,
                isLocked: false,
                requirePasswordReset: false
            }
        }
    })
        .then(results => {
            const usr = {
                id: results.data.login.user.id,
                username: results.data.login.user.email,
                firstName: results.data.login.user.firstName,
                lastName: results.data.login.user.lastName,
                passwordDate: results.data.login.user.passwordDate,
                kareoId: results.data.login.user.kareoId,
                role: results.data.login.user.roles[0].id,
                timestamp: new Date(),
                isActive: results.data.login.user.isActive,
                isLocked: results.data.login.user.isLocked,
                requirePasswordReset: results.data.login.user.requirePasswordReset
            };
            usr.roles.sort((a,b) => a.id - b.id);
            usr.role = usr.roles[0].id;
            setUser(usr);

            localStorage.setItem(`hpm.charge.pw.attempts`, `${0}`);
            localStorage.setItem(API_KEY, results.data.login.token);
            // navigate(`/app/chargesheet`);
            handleNavAfterLogin();

            return results;
        })
        .catch(error => {
            console.log("Error: ", error)
            return error
        })
}
export const isLoggedIn = () => {
    const user = getUser();
    if (!user) { setUser({}); return false; }

    if (!!user.username && (Math.abs(new Date() - new Date(user.timestamp)) / 36e5) > 24) { // 3600 seconds per hour, timestamp is in milliseconds (*1000) -> expires after 24 hours
        setUser({});
        return false;
    }

    return !!user.username;
}
export const logout = callback => {
    localStorage.removeItem(STORED_NOTIFICATIONS); // remove notifications for this user
    setUser({});
    localStorage.removeItem(API_KEY);
    callback && callback();
}
